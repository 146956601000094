
import { defineComponent, ref, onMounted, onActivated, computed } from "vue";
import { MBDatatablePlus } from "magicbean-saas-common";
import { MenuComponent } from "@/assets/ts/components";
import { ApiBase, ApiDraftOrders } from "@/core/api";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import { SalesOrderTable } from "@/core/directive/interface/salesOrder";
import { FilterOption, SortOption } from "@/core/directive/interface/common";
import { NumberOrString } from "@/core/directive/type/common";
import {
  getSalesOrderFulfillmentStatus,
  getSalesOrderStatus,
} from "@/core/directive/function/salesOrder";
import store from "@/store";
import AddModal from "@/views/sales-order/draft-orders/AddModal.vue";
import FilterDropdown from "@/views/sales-order/draft-orders/FilterDropdown.vue";
import {
  commonChangeFilterAddDate,
  formatDate,
  formatDateTime,
  setModuleBCN,
} from "@/core/directive/function/common";
import { useRoute } from "vue-router";
import { Mutations } from "@/store/enums/StoreEnums";
import { getDraftOrderRouteUrl } from "@/core/directive/function/doubleSaleOrder";
import WindowResize from "../layout/WindowResize.vue";

export default defineComponent({
  name: "sales-order-draft-orders",
  props: {
    type: {
      type: Number,
      default: 0, // 0: sales order draft orders 1: double sale order draft orders
    },
  },
  components: {
    MBDatatablePlus,
    AddModal,
    FilterDropdown,
    WindowResize,
  },
  setup(props) {
    const { t } = useI18n();
    const route = useRoute();

    const loading = ref(true);
    const tableData = ref<Array<SalesOrderTable>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(
      store.getters.currentConfigPageSize(route.name)
    );
    const search = ref<string>("");
    const filterOptions = ref<Array<FilterOption>>([]);

    const sortOptions = ref<Array<SortOption>>([
      { field: "created_at", direction: "desc" },
    ]);

    const options = ref({
      distribution_model: new Map([]),
      payment_status: new Map([
        [1, t("salesOrder.paymentStatusPending")],
        [2, t("salesOrder.paymentStatusPaid")],
      ]),
    });

    const tableHeader = ref([
      {
        name: t("salesOrder.type"),
        key: "type",
        sortable: false,
      },
      {
        name: t("salesOrder.creationDate"),
        key: "created_at",
        sortable: true,
      },
      {
        name: t("salesOrder.deliveryDateAdjustment"),
        key: "due_date",
        sortable: true,
      },
      {
        name: t("common.customer"),
        key: "customer",
        sortable: false,
      },
      {
        name: t("salesOrder.amount"),
        key: "amount",
        sortable: false,
      },
      {
        name: t("salesOrder.paymentStatus"),
        key: "deposit_payment_status",
        sortable: false,
      },
      {
        name: t("common.action"),
        key: "actions",
        sortable: false,
      },
    ]);

    const init = () => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route);
      updateList();
      getTaggingData();
    };

    const getTaggingData = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: ["sales_order_distribution_mode"],
      });
      if (data.code == 0) {
        data.data.sales_order_distribution_mode.items.map((item) => {
          options.value.distribution_model.set(Number(item.value), item.label);
        });
      }
    };

    onMounted(() => {
      if (store.getters.getAliveList.length === 0) {
        init();
      }
    });

    onActivated(() => {
      init();
    });

    const otherFilterOptions = computed(() => {
      return props.type === 0
        ? []
        : [
            {
              field: "store_id",
              value: ["16", "25"],
              condition: "in",
            },
          ];
    });

    const getDraftOrderList = async () => {
      loading.value = true;
      const { data } = await ApiDraftOrders.getDraftOrderList({
        page: currentPage.value,
        page_size: pageSize.value,
        search_value: search.value,
        filter_group: [...filterOptions.value, ...otherFilterOptions.value],
        sort_orders: sortOptions.value,
      });
      loading.value = false;
      if (data.code == 0) {
        tableData.value.splice(0, tableData.value.length, ...data.data.items);
        total.value = data.data.total;
      }
    };

    const updateList = () => {
      getDraftOrderList();
    };

    const onCurrentPageChange = (page: number) => {
      currentPage.value = page;
      updateList();
    };

    const onRowsPerPageChange = (pagesize: number) => {
      pageSize.value = pagesize;
      updateList();
      store.commit(Mutations.SET_CURRENT_PAGE_CONFIG, {
        key: route.name,
        data: {
          pageSize: pageSize.value,
        },
      });
    };

    const getLabel = (val, key) => {
      return options.value[key].find((item) => {
        return item.value == val;
      })?.label;
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      updateList();
    };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (val[item] != "" && val[item] != null) {
          if (item == "created_at_start") {
            filterArr.push({
              field: "created_at",
              value: commonChangeFilterAddDate(val[item], 1),
              condition: "gteq",
            });
          } else if (item == "created_at_end") {
            filterArr.push({
              field: "created_at",
              value: commonChangeFilterAddDate(val[item]),
              condition: "lteq",
            });
          } else if (item == "order_number") {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "like",
            });
          } else {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "eq",
            });
          }
        }
      }
      filterOptions.value.splice(0, filterOptions.value.length, ...filterArr);
      search.value = "";
      currentPage.value = 1;
      updateList();
    };

    const handleFilterReset = () => {
      resetFilter();
      updateList();
    };

    const resetFilter = () => {
      filterOptions.value.splice(0, filterOptions.value.length);
    };

    const debounceSearch = _.debounce(updateList, 1000);

    const searchItems = () => {
      currentPage.value = 1;
      debounceSearch();
    };

    const removeSearch = () => {
      search.value = "";
      currentPage.value = 1;
      updateList();
    };

    const splitString = computed(() => {
      return (string) => {
        return string.split(",");
      };
    });

    const statusClass = (val) => {
      let statusClass = "";

      switch (val) {
        case 1:
          statusClass = "badge-light-primary";
          break;
        case 2:
          statusClass = "badge-light-success";
          break;
        default:
          statusClass = "badge-white";
          break;
      }
      return statusClass;
    };

    const orderStatus = computed(() => {
      return (status: string) => {
        return getSalesOrderStatus(status, t);
      };
    });

    const fulfillmentStatus = computed(() => {
      return (status: NumberOrString) => {
        return getSalesOrderFulfillmentStatus(status, t);
      };
    });

    return {
      t,
      store,
      formatDate,
      formatDateTime,
      getDraftOrderRouteUrl,
      loading,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      options,
      getDraftOrderList,
      onCurrentPageChange,
      onRowsPerPageChange,
      onColumnSort,
      getLabel,
      searchItems,
      handleFilter,
      handleFilterReset,
      updateList,
      removeSearch,
      statusClass,
      splitString,
      orderStatus,
      fulfillmentStatus,
    };
  },
});
